import * as React from "react";
import Header from "../common/components/organism/Header";
import MarketCard from "../common/components/organism/Cards/MarketCard";
import Footer from "../common/components/organism/Footer";
import { Maxwidth, ArticleCard, Input, Select } from '@subtropico/shared'



const IndexPage = () => (
    <>
        <Header />
        <Maxwidth>
             
            <div className="my-10 grid lg:grid-cols-4">
                <MarketCard image={{ src: 'https://source.unsplash.com/random', alt: '' }} marketName="Lekki Potato Market" />
            </div>
            <div className="my-10 grid lg:grid-cols-4 h-64 gap-x-10">
               <ArticleCard />
               <ArticleCard position="top" />
            </div>
            <div>
                <Input label="A test input" />
            </div>
            <div>
                <Select label="A select object">
                    <option value="">Hello</option>
                    <option value="">Hello</option>
                    <option value="">Hello</option>
                    <option value="">Hello</option>
                </Select>
            </div>
        </Maxwidth>
        <Footer />
    </>
);

export default IndexPage;
